import { createStore } from 'vuex'
import i18n from '@/i18n'
import router from '@/router'
import device from '@/device'
import service from '@/service'
import geolocation from '@/geolocation'
import models from '@/models'
import util from '@/util'

function _updateItems(items, updates, Model) {
  // update or add Objects like User, Order with Objects from updates
  Object.values(updates).forEach((init) => {
    let item = items.find((item) => item.id === init.id)
    if (item) {
      item.update(init)
    } else {
      items.push(new Model(init))
    }
  })
  return items
}

function _saveItems(key, items) {
  return device
    .save(
      key,
      items.map((item) => item.json())
    )
    .then((r) => Promise.resolve(r))
    .catch((e) => Promise.reject(e))
}

export default createStore({
  state: {
    VERSION: '1.0.21',
    VERSION_APP: '',
    DEBUG: location.hostname === 'localhost',
    // for museum app
    museum: import.meta.env.VITE_APP_ENV === 'MUSEUM',
    // museum: false,
    museumFull: false,

    showIntro: true,

    onLine: navigator.onLine,
    app: false,
    initialized: false,
    loading: false,
    working: false,
    more: false,
    sideContent: false, // false, Plan, BiePlan, BieMap (photo)
    mapDefault: true,

    locating: false,
    position: null,
    location: null,
    filterDistance: 1000,

    // shownews: true,
    // newsViewed: 0,
    // newsServer: '',
    mediaServer: '',
    placePreviewServer: '',

    // news: [],
    lines: [],
    features: [],
    places: [],
    authors: [],

    defaultUploads: {},
    userUploads: {},

    latlngDefaultUploads: {},
    latlngUserUploads: {},

    user: null,

    sitetitle: '',
    title: null,
    title2: null,
    titlelink: null,
    place: null,
    author: null
  },
  getters: {
    getFeatureById: (state) => (id) =>
      state.features.find((item) => item.id === parseInt(id)),
    getLineById: (state) => (id) =>
      state.lines.find((item) => item.id === parseInt(id)),
    getPlaceById: (state) => (id) =>
      state.places.find((item) => item.id === parseInt(id)),
    getRandomPlace: (state) =>
      state.places[Math.round((state.places.length - 1) * Math.random())],
    getAuthorById: (state) => (id) =>
      state.authors.find((item) => item.id === parseInt(id))
    // getLatestNewsUpdate: (state) => () =>
    //   state.news.reduce(
    //     (latest, item) => (item.updatedAt > latest ? item.updatedAt : latest),
    //     0
    //   )
  },
  mutations: {
    onLine(state, onLine) {
      state.onLine = onLine
      if (onLine) {
        service.syncQueue()
      }
    },
    app(state, app) {
      state.app = app
    },
    initialized(state, initialized) {
      state.initialized = initialized
    },
    loading(state, loading) {
      state.loading = loading
    },
    working(state, working) {
      state.working = working
    },
    locating(state, locating) {
      state.locating = locating
    },
    more(state, more) {
      state.sideContent = false
      state.more = more
    },
    sideContent(state, sideContent) {
      state.more = false
      state.sideContent = sideContent
    },
    // shownews(state, shownews) {
    //   state.shownews = shownews
    // },
    toggleSize(state) {
      state.mapDefault = !state.mapDefault
    },
    sitetitle(state, sitetitle) {
      state.sitetitle = sitetitle
    },
    title(state, title) {
      state.sitetitle = title || '' // overwrite later
      state.title = title
    },
    title2(state, title2) {
      state.title2 = title2
    },
    titlelink(state, titlelink) {
      state.titlelink = titlelink
    },
    position(state, position) {
      state.position = position
    },
    location(state, location) {
      state.location = location
    },
    place(state, place) {
      state.place = place
    },
    filterDistance(state, filterDistance) {
      state.filterDistance = filterDistance

      if (state.latlngUserUploads?._filter) {
        state.latlngUserUploads._filter.filter_distance = filterDistance
        state.latlngUserUploads.start()
      }
      if (state.latlngDefaultUploads?._filter) {
        state.latlngDefaultUploads._filter.filter_distance = filterDistance
        state.latlngDefaultUploads.start()
      }
    },
    author(state, author) {
      state.author = author
    },
    addAuthor(state, init) {
      let author = state.authors.find((item) => item.id === init.id)

      if (!author) {
        state.authors.push(new models.User(init))
      } else {
        author.update(init)
      }
    },
    museum(state, museum) {
      state.museum = museum
    },
    museumFull(state, museumFull) {
      state.museumFull = museumFull
    },
    clearMedia(state) {
      state.latlngUserUploads = {}
      state.latlngDefaultUploads = {}
    }
  },
  actions: {
    initialize: ({ state }) => {
      return (
        device
          .load('showIntro', true)
          .then((init) => {
            state.showIntro = init
            //   return device.load('newsViewed', 0)
            // })
            // .then((init) => {
            //   state.newsViewed = init
            return device.load('user')
          })
          .then((init) => {
            state.user = new models.User(init)
          })
          // no error if no user saved
          .catch(() => {})
          .finally(() => {
            state.initialized = true
            return Promise.resolve()
          })
      )
    },
    setShowIntro: ({ state }, showIntro) => {
      state.showIntro = showIntro
      return device
        .save('showIntro', showIntro)
        .finally(() => Promise.resolve())
    },
    login: ({ state }, data) => {
      return service
        .login(data)
        .then((init) => {
          state.user = new models.User(init)
          return device.save('user', state.user.json())
        })
        .catch((e) => Promise.reject(e))
    },
    logout: ({ state }) => {
      state.more = false
      state.sideContent = false
      state.user = null
      // state.newsViewed = 0
      return device.remove('user').finally(() => service.logout(state.user))
    },
    removeUser: ({ state }) => {
      state.user = null
      return device.remove('user')
    },
    init: ({ state }) => {
      // intro
      device
        .load('intro')
        .then((seen) => {
          state.intro = seen
        })
        .catch(() => {})
        .finally(() => Promise.resolve())
    },
    loadDashboard: ({ state }) => {
      // load data
      if (state.onLine) {
        return (
          service
            .loadDashboard()
            .then((dashboard) => {
              state.mediaServer = dashboard.mediaServer
              // state.newsServer = dashboard.newsServer
              state.placePreviewServer = dashboard.placePreviewServer
              state.defaultUploads = new models.PagedMedia(
                dashboard.media.defaultUploads,
                { uploadType: 'defaultUpload' }
              )
              dashboard.media.userUploads?.media.map(
                (m) => (m.uploadType = 'userUpload')
              )
              state.userUploads = new models.PagedMedia(
                dashboard.media.userUploads,
                { uploadType: 'userUpload' }
              )
              _updateItems(state.features, dashboard.features, models.Feature)
              _updateItems(state.lines, dashboard.lines, models.Line)
              _updateItems(state.places, dashboard.places, models.Place)
              // _updateItems(state.news, dashboard.news, models.News)

              state.lines.forEach((line) => line.sort())

              return device.save('mediaServer', state.mediaServer)
            })
            // .then(() => device.save('newsServer', state.newsServer))
            .then(() =>
              device.save('placePreviewServer', state.placePreviewServer)
            )
            .then(() => _saveItems('features', state.features))
            .then(() => _saveItems('lines', state.lines))
            .then(() => _saveItems('places', state.places))
            // .then(() => _saveItems('news', state.news))
            .then(() =>
              device.save('defaultUploads', state.defaultUploads.json())
            )
            .then(() => device.save('userUploads', state.userUploads.json()))
            .catch((e) => Promise.reject(e))
        )
      } else {
        let promises = []
        let _models = ['Line', 'Feature', 'Place', 'Medium', 'Medium']
        ;['mediaServer', 'defaultUploads', 'userUploads'].forEach((item) => {
          promises.push(
            device
              .load(item)
              .then((init) => {
                state[item] =
                  item === 'mediaServer' ? init : new models.PagedMedia(init)
                return Promise.resolve()
              })
              .catch((e) => Promise.reject(e))
          )
        })
        ;[
          'lines',
          'features',
          'places',
          // 'news',
          'defaultUploads',
          'userUploads'
        ].forEach((item, i) => {
          promises.push(
            device
              .load(item)
              .then((inits) => {
                _updateItems(state[item], inits, models[_models[i]])
                return Promise.resolve()
              })
              .catch((e) => Promise.reject(e))
          )
          return Promise.all(promises)
        })
      }
    },
    loadPlaces: ({ state }) => {
      state.working = true
      return service
        .loadPlaces()
        .then((places) => {
          state.working = false
          return _saveItems(
            'places',
            _updateItems(state.places, places, models.Place)
          )
        })
        .catch((e) => {
          state.working = false
          Promise.reject(e)
        })
    },
    loadMedia: ({ state }) => {
      return service
        .loadMedia()
        .then((media) => {
          // TODO?
          state.media = media
          return Promise.resolve()
        })
        .catch((e) => Promise.reject(e))
    },
    selectPlace: ({ state, dispatch }, id) => {
      if (id !== state.place?.id && !['', null, undefined].includes(id)) {
        dispatch('setPlace', id)
      } else {
        state.place = null
      }
    },
    setPlace: ({ state, commit, getters }, id) => {
      commit('clearMedia')
      let place = getters.getPlaceById(id)
      state.place = place

      if (state.onLine) {
        state.working = true
        return service
          .loadPlace(id)
          .then((init) => {
            place.update(init)

            state.latlngUserUploads = new models.PagedMedia([], {
              uploadType: 'userUpload',
              filter_lat: place.lat,
              filter_lng: place.lng,
              filter_distance: state.filterDistance
            })
            state.latlngUserUploads.load()
            state.latlngDefaultUploads = new models.PagedMedia([], {
              uploadType: 'defaultUpload',
              filter_lat: place.lat,
              filter_lng: place.lng,
              filter_distance: state.filterDistance
            })
            state.latlngDefaultUploads.load()

            state.working = false
            return Promise.resolve(place)
          })
          .catch((e) => {
            state.working = false
            return Promise.reject(e)
          })
      } else {
        return Promise.resolve(place)
      }
    },
    setLatLng: ({ state, commit }, latlng) => {
      commit('clearMedia')
      if (latlng) {
        state.location = {
          short: util.shortLatLng(latlng),
          latlng: latlng,
          place: geolocation.findNearestPlace(
            latlng,
            state.places.filter((place) => place.lines?.length)
          ),
          attraction: geolocation.findNearestPlace(
            latlng,
            state.places.filter((place) => !place.lines?.length)
          )
        }
        if (router.currentRoute.value.name !== 'photo') {
          state.latlngUserUploads = new models.PagedMedia([], {
            uploadType: 'userUpload',
            filter_lat: latlng.lat,
            filter_lng: latlng.lng,
            filter_distance: state.filterDistance
          })
          state.latlngUserUploads.load()
          state.latlngDefaultUploads = new models.PagedMedia([], {
            uploadType: 'defaultUpload',
            filter_lat: latlng.lat,
            filter_lng: latlng.lng,
            filter_distance: state.filterDistance
          })
          state.latlngDefaultUploads.load()
          if (!state.museum) {
            router.push({
              name: 'latlng',
              params: {
                latlng: state.location.short.join(',')
              }
            })
          }
        }
        return Promise.resolve()
      } else {
        state.location = null
        // state.position = null
        commit('clearMedia')
        return Promise.reject()
      }
    },
    setPosition: ({ state }) => {
      state.locating = true
      return geolocation
        .getLocation()
        .then((latlng) => {
          if (latlng) {
            state.position = latlng
            return Promise.resolve()
          } else {
            state.position = null
            return Promise.reject()
          }
        })
        .catch((e) => {
          state.position = null
          return Promise.reject(e)
        })
        .finally(() => (state.locating = false))
    },
    setLocation: ({ state }) => {
      state.locating = true
      return geolocation
        .getLocation()
        .then((latlng) => {
          state.position = latlng
          if (state.sideContent === 'BiePlan') {
            return Promise.resolve()
          } else if (state.sideContent === 'Plan') {
            let place
            if (latlng) {
              place = geolocation.findNearestPlace(
                latlng,
                state.places.filter((place) => place.lines?.length)
              )
              state.location = {
                latlng: latlng,
                place: place,
                attraction: geolocation.findNearestPlace(
                  latlng,
                  state.places.filter((place) => !place.lines?.length)
                )
              }
            } else {
              state.location = null
            }
            return place
              ? Promise.resolve(place)
              : Promise.reject(i18n.global.t('setLocation.toofar'))
          }
        })
        .catch((e) => {
          state.position = null
          state.location = null
          return Promise.reject(e)
        })
        .finally(() => (state.locating = false))
    }
    // newsViewed: ({ state }, newsViewed) => {
    //   state.newsViewed = newsViewed
    //   return device.save('newsViewed', state.newsViewed)
    // }
  }
})
